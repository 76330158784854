/** @jsxImportSource @emotion/react */
import { Tooltip as MaterialTooltip, makeStyles } from '@material-ui/core';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { is } from 'ramda';
import React from 'react';

export interface ITooltipProps {
  arrow?: boolean;
  children: React.ReactElement;
  interactive?: boolean;
  open?: boolean;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  title?: string | React.ReactElement;
  theme?: 'DARK' | 'LIGHT';
}

/**
 * See:
 *  https://material-ui.com/components/tooltips/
 */
export const Tooltip = React.forwardRef(function Tooltip(
  {
    arrow = false,
    children,
    interactive,
    placement,
    title = '',
    theme = 'DARK',
    open,
  }: ITooltipProps,
  ref,
) {
  const classStyles = useTooltipStyles();

  const isPlainText = React.useMemo(() => is(String, title), [title]);

  return (
    <MaterialTooltip
      title={title}
      aria-label={isPlainText ? (title as string) : undefined}
      arrow={arrow}
      innerRef={ref}
      placement={placement}
      classes={{
        tooltip: theme === 'DARK' ? classStyles.plainText : classStyles.element,
      }}
      interactive={interactive}
      open={open}
    >
      {children}
    </MaterialTooltip>
  );
});

const useTooltipStyles = makeStyles({
  plainText: {
    fontSize: 15,
    padding: '8px 12px',
    border: `solid 1px ${color.format(-0.1)}`,
    backgroundColor: color.create('#717171').alpha(0.98).hex(),
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  element: {
    backgroundColor: 'WHITE',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    border: `solid 1px ${color.format(-0.1)}`,
    fontSize: 14,
  },
});
